/* .loader.xs */

.loader.xs {
  width: 18px;
  height: 18px;
}

.loader.xs .loader-div,
.loader.xs .loader-div-div {
  width: 15px;
  height: 8.5px;
}

.loader.xs .loader-span {
  width: 2px;
}

/* .loader.sm */

.loader.sm {
  width: 36px;
  height: 36px;
  margin: 2px;
}

.loader.sm .loader-div,
.loader.sm .loader-div-div {
  width: 30px;
  height: 17px;
}

.loader.sm .loader-span {
  width: 4px;
}

/* .loader.md */

.loader.md {
  width: 72px;
  height: 72px;
  margin: 4px;
}

.loader.md .loader-div,
.loader.md .loader-div-div {
  width: 60px;
  height: 34px;
}

.loader.md .loader-span {
  width: 8px;
}

/* .loader */

.loader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-div,
.loader-div-div {
  position: absolute;
}

.loader-div:nth-of-type(2) {
  transform: rotate(60deg);
}

.loader-div:nth-of-type(3) {
  transform: rotate(-60deg);
}

.loader-div-div {
  width: 100%;
  height: 100%;
  position: relative;
}

.loader-span {
  position: absolute;
  height: 0%;
  z-index: 999999;
}

.loader .h1 {
  left: 0;
  animation: load1 1.8s ease infinite;
}

.loader .h2 {
  right:0;
  animation: load2 1.8s ease .15s infinite;
}

.loader .h3 {
  right:0;
  animation: load3 1.8s ease .3s infinite;
}

.loader .h4 {
  right:0;
  animation: load4 1.8s ease .45s infinite;
}

.loader .h5 {
  left: 0;
  animation: load5 1.8s ease .6s infinite;
}

.loader .h6 {
  left: 0;
  animation: load6 1.8s ease .75s infinite;
}

@keyframes load1 {
  0%{bottom:0;height:0}
  6.944444444%{bottom:0;height:100%}
  50%{top:0;height:100%}
  59.944444433%{top:0;height:0}
}

@keyframes load2 {
  0%{top:0;height:0}
  6.944444444%{top:0;height:100%}
  50%{bottom:0;height:100%}
  59.944444433%{bottom:0;height:0}
}

@keyframes load3 {
  0%{top:0;height:0}
  6.944444444%{top:0;height:100%}
  50%{bottom:0;height:100%}
  59.94444443%{bottom:0;height:0}
}

@keyframes load4 {
  0%{top:0;height:0}
  6.944444444%{top:0;height:100%}
  50%{bottom:0;height:100%}
  59.94444443%{bottom:0;height:0}
}

@keyframes load5 {
  0%{bottom:0;height:0}
  6.944444444%{bottom:0;height:100%}
  50%{top:0;height:100%}
  59.94444443%{top:0;height:0}
}

@keyframes load6 {
  0%{bottom:0;height:0}
  6.944444444%{bottom:0;height:100%}
  50%{top:0;height:100%}
  59.94444443%{top:0;height:0}
}
