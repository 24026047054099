.ck.ck-toolbar {
  border: 0 !important;
}

.ck.ck-content {
  border-right: 0 !important;
  border-bottom: 0 !important;
  border-left: 0 !important;
  padding: 8px 32px !important;
}

.ck-container-md .ck.ck-content {
  min-height: 122px;
}

.ck-container-lg .ck.ck-content {
  min-height: 180px;
}

.ck.ck-toolbar,
.ck.ck-content {
  background: transparent !important;
}

/* .dark */

.dark .ck-reset_all :not(.ck-reset_all-excluded *),
.dark .ck.ck-reset_all {
  color: #fff;
}

.dark .ck.ck-dropdown__panel,
.dark .ck.ck-list {
  background: rgb(29, 29, 36);
}

.dark .ck.ck-list__item .ck-button:hover:not(.ck-disabled),
.dark .ck.ck-button:not(.ck-disabled):hover,
.dark a.ck.ck-button:not(.ck-disabled):hover,
.dark .ck.ck-button.ck-on,
.dark a.ck.ck-button.ck-on {
  background: rgb(49, 49, 56);
}

.dark .ck.ck-button:active,
.dark .ck.ck-button:focus,
.dark a.ck.ck-button:active,
.dark a.ck.ck-button:focus {
  box-shadow: none;
  border-color: transparent !important;
}
